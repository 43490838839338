<template>
  <el-dialog v-loading="loading" element-loading-text="正在提交中..." :destroy-on-close="true" :title="isEditDialogTitle[Number(editType)-1]" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="740px" :before-close="dialogClose">
    <el-row style="text-align:left">
      <el-col :span="12">标题 : {{editId.title}}</el-col>
      <el-col :span="12">审批单号 : {{editId.auditCode}}</el-col>
      <el-col :span="12">发放金额 : {{editId.integralGrantAmount || 0}}</el-col>
      <el-col :span="12">撤回金额 : {{editId.integralWithdrawAmount || 0}}</el-col>
      <el-col :span="12">总人数 : {{editId.integralNumber}}</el-col>
      <el-col :span="12" v-show="editId.fileUrl">下载文件 :
        <a :href="editId.fileUrl" style="margin:0 10px">
          <el-button type="text" icon="el-icon-download">下载</el-button>
        </a>
      </el-col>
    </el-row>
    <div class="list-box">
      <el-table border stripe :data="list">
        <el-table-column type="index" align="center" label="序号" width="90"></el-table-column>
        <!-- <el-table-column prop="number" align="center" label="编号"></el-table-column> -->
        <el-table-column prop="customerName" align="center" label="客户名称"></el-table-column>
        <el-table-column prop="exchangeAmount" align="center" label="面额"></el-table-column>
        <el-table-column prop="exchangeCode" align="center" label="兑换码" v-if="editType==4"></el-table-column>
        <el-table-column prop="usedPhone" align="center" label="手机号"></el-table-column>
        <el-table-column width="120px" label="操作" v-if="editShow">
          <template slot-scope="scope">
            <div>
              <el-popover placement="right" width="400" trigger="click" v-model="scope.row.isSelected">
                <EditBatchUpdate v-if="scope.row.isSelected" :isSelected.sync="scope.row.isSelected" :editItem.sync="scope.row" @changeListItem="getDateil" />
                <el-button type="text" slot="reference" icon="el-icon-edit">修改</el-button>
              </el-popover>
              <el-button type="text" icon="el-icon-delect" @click="delectItem(scope.row.infoId)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
      </el-pagination>
    </div>
    <div>
      <el-button type="primary" v-debounce="submitApproval" v-if="editType == 3" :loading="loading" :disabled="loading">{{loading?'正在提交':'确认提交'}}</el-button>
      <el-button type="info" @click="dialogClose()">关闭窗口</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getCookies } from '@/utils/utils'
import { getBatchVoucherList, deleteAutonym, submitApproval } from '@/api/codes';
import { getBatchInfoList } from '@/api/integrals';
import EditBatchUpdate from './EditBatchUpdate.vue'
export default {
  props: ['isAddDialog', 'editId', 'editType'],
  components: { EditBatchUpdate },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
      isEditDialogTitle: ['查看批次详情', '编辑批次详情', '提交批次详情'],
    }
  },
  computed: {
    editShow() {
      return [2].indexOf(this.editType) != -1 || !this.editType
    }
  },
  created() {
    this.editType && this.editId && this.getDateil();
  },
  methods: {
    dialogClose() {
      this.active = 0;
      this.$emit('update:isAddDialog', false)
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDateil();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getDateil();
    },
    submitApproval(id) {
      let that = this;
      if (this.list.length < 1 || this.list.length == 0) {
        this.$message({
          title: '错误',
          type: 'error',
          message: '批次客户为0，不可提交',
          duration: 1500
        });
        return false;
      }
      this.loading = true
      submitApproval({ batchId: that.editId.batchId, submitBy: getCookies('sysId') })
        .then((response) => {
          this.dialogClose();
          this.loading = false
          that.$message({
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
        }).catch(err => {
          this.loading = false
          this.dialogClose();
        })
    },
    delectItem(id) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAutonym(id).then((response) => {
          if (response.code == 200) {
            this.list = this.list.filter(item => item.infoId !== id);
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    getDateil() {
      let that = this;
      let param = {
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };

      getBatchInfoList(this.editId.batchId, param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            let list = response.data.records;
            that.list = list.map(v => {
              return { ...v, isSelected: false }
            })
          }

        })
    },
  }
}
</script>
<style scoped>
.list-box {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  max-height: 600px;
  margin: 0px auto 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #b4b4b4;
}
.el-col-12 {
  margin: 10px 0;
}
.el-col-12:nth-child(2n) {
  text-align: right;
}
</style>