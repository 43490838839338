<template>
  <el-form @submit.native.prevent ref="form" :model="form" :rules="rules" label-width="120px">
    <el-row>
      <!-- <el-col :span="22">
        <el-form-item label="编号">
          <el-input v-model="form.number" maxlength="16" placeholder="请输入"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="22">
        <el-form-item label="用户名" prop="customerName">
          <el-input v-model="form.customerName" maxlength="16" placeholder="请输入"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="手机号码" prop="usedPhone">
          <el-input v-model="form.usedPhone" maxlength="11" placeholder="请输入电话(7-11位数字)"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="面额（￥）" prop="exchangeAmount" disabled>
          <el-input placeholder="请填写金额" maxlength="7" v-model="form.exchangeAmount" @input="changeCheck" type="number"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item>
          <el-button type="primary" v-debounce="onSubmit">立即保存</el-button>
          <el-button @click="dialogClose">取消</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { singleUpdateIntegral } from '@/api/integrals';
export default {
  name: 'EditBatchUpdate',
  props: ['editItem', 'isSelected'],
  data() {
    return {
      form: {
        // number: '',
        usedPhone: '',
        exchangeAmount: '',
        customerName: '',
      },
      rules: {
        usedPhone: [
          { required: true, message: '手机号码不可为空', trigger: 'blur' },
        ],
        customerName: [
          { required: true, message: '用户名不可为空', trigger: 'blur' },
        ],
        exchangeAmount: [
          { required: true, message: '金额不可为空', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    isSelected(n, o) {
      if (n) {
        // this.form.number = '';
        this.form.usedPhone = '';
        this.form.exchangeAmount = '';
        this.form.customerName = '';
        this.form.infoId = '';
      }
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.editItem));
  },
  methods: {
    changeCheck() {
      let exp = /^(\-)*(\d+)\.(\d\d).*$/;
      this.form.exchangeAmount = this.form.exchangeAmount.replace(exp, '$1$2.$3');
    },
    dialogClose() {
      this.$emit('update:isSelected', false)
    },

    onSubmit(formName = 'form') {

      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (form.usedPhone) {
            if (String(form.usedPhone).length < 11) {
              this.$message.error({
                title: '错误',
                message: '请检查完善手机号码',
                duration: 1500
              });
              return false;
            }
          }
          form.infoId = this.editItem['infoId']
          singleUpdateIntegral(form)
            .then((response) => {
              if (response.code == 200) {
                form['isSelected'] = false;
                that.$emit('changeListItem', form)
                that.dialogClose();
              }
              that.$message({
                title: '错误',
                type: response.code == 200 ? 'success' : 'error',
                message: response.msg || response.error_msg || response.message,
                duration: 1500
              });

            })
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查重试',
            duration: 1500
          });
          return false;
        }
      })


    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
</style>