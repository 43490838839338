/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-03 17:29:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\api\user.js
 */
import { post, fetch, deletes, patch, put } from '@/utils/http'
import axios from '@/utils/http';


//------------------------------------------------------------
//确认导入积分兑换码(生成多个兑换码)
export function confirmImport(params) {
  return post('/wlynIntegral/confirmImport', params);
}

// 直接发放确认批量导入列表(生成多个直接发放)
export function realConfirmImport(exchangeType, title, list, auditCode, fileUrl, orgId) {
  return axios.post(`/wlynIntegral/realConfirmImport?exchangeType=${exchangeType}&title=${title}&auditCode=${auditCode}&fileUrl=${fileUrl}&orgId=${orgId}`, list, { timeout: 180000 });
}

//新增单个实名或直接发放
export function addRealNameOrDirect(params) {
  return post('/wlynIntegral/addRealNameOrDirect', params);
}

//生成单个兑换码
export function singleExchangeCode(params) {
  return post('/wlynIntegral/singleExchangeCode', params);
}

// 
export function singleUpdateIntegral(params) {
  return post('/wlynIntegral/singleUpdateIntegral', params);
}

// 查看(根据批次id获取客户列表)
export function getBatchInfoList(id, params) {
  return fetch('/wlynIntegral/getBatchInfoList/' + id, params);
}

// 按客户显示撤回
export function removeBack(params) {
  return fetch('/wlynIntegral/removeBack/' + params);
}

// 根据批次id获取实名发放客户列表
export function displayByCustomer(params) {
  return fetch('/wlynIntegral/displayByCustomer', params);
}

// 根据状态查询积分领取数量
export function selectIntegralByIsReceive(params) {
  return axios.get('/wlynIntegral/selectIntegralByIsReceive', { params });
}

// 发送积分短信通知
export function getIntegralSms(params) {
  return axios.get('/wlynIntegral/getIntegralSms', { params });
}

//查询积分发放总金额
export function getIntegralGrantTotalAmount(params) {
  return axios.get('/wlynIntegral/getIntegralGrantTotalAmount', { params })
}
//导出积分详情数据excel
export function exportIntegralInfoExcel(params) {
  return axios.get('/wlynIntegral/exportIntegralInfoExcel', { params })
}
